<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="运营方编号">
              <el-input v-model="searchForm.channelCode" placeholder="请输入要搜索的运营方编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运营方名称">
              <el-input v-model="searchForm.channelName" placeholder="请输入要搜索的运营方名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input v-model="searchForm.channelSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.enabled" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.enableStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <el-radio-group v-model="selectChannel" class="table-radio-group">
        <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
          <template slot="btn-inner">
            <el-button type="primary" @click="$router.push({ name: 'channelEdit', params: { editMode: 'add' } })">
              新增运营方
            </el-button>
            <el-button type="primary" @click="associated">
              关联核心企业
            </el-button>
          </template>
          <template slot="table-columns-before">
            <el-table-column fixed="left" label="选择" align="center" width="60">
              <template slot-scope="scope">
                <el-radio :label="scope.row.channelId" />
              </template>
            </el-table-column>
            <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
          </template>
          <template slot="table-columns-after">
            <el-table-column fixed="right" label="操作" width="140">
              <template v-slot="scope">
                <el-link v-if="scope.row.enabled === 0" type="primary" class="text-btn" @click="enableChannel(scope.row.channelId, 1)">
                  启用
                </el-link>
                <el-link v-if="scope.row.enabled === 1" type="danger" class="text-btn" @click="enableChannel(scope.row.channelId, 0)">
                  禁用
                </el-link>
                <el-link type="primary" class="text-btn" @click="$router.push({ name: 'channelEdit', params: { editMode: 'edit' }, query: { channelId: scope.row.id } })">
                  编辑
                </el-link>
                <el-link type="primary" class="text-btn" @click="$router.push({ name: 'channelDetail', query: { channelId: scope.row.id } })">
                  详情
                </el-link>
              </template>
            </el-table-column>
          </template>
        </ics-table-inner>
      </el-radio-group>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" cancel-title="取消" class="form-dialog" @submit="confirmInvoice">
      <div class="partition-area">
        <search-inner :search-id="1" :search-form="searchNotCoreForm" :fold-btn="true" @submit-search="searchNotCore" @clear-search="resetNotCore">
          <template>
            <el-col :span="6">
              <el-form-item label="核心企业名称：">
                <el-input v-model="searchNotCoreForm.coreName" placeholder="请输入要搜索的核心企业名称" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码：">
                <el-input v-model="searchNotCoreForm.coreSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
              </el-form-item>
            </el-col>
          </template>
        </search-inner>
        <div class="list-btn-inner" />
        <table-inner v-loading="loadingCore.list" :table-header="false" :table-data="notChannelOfCore.list" :page-num="notChannelOfCore.pageNum" :page-size="notChannelOfCore.pageSize" :pages="notChannelOfCore.pages" :page-id="notChannelOfCore.pageId" @changePageNum="changeNotCorePageNum" @selection-change="handleSelectionChange">
          <template slot="table-columns">
            <el-table-column type="selection" align="left" min-width="50" />
            <el-table-column prop="coreCode" label="核心企业编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="coreName" label="核心企业名称" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="coreSocialNo" label="统一社会信用代码" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          </template>
        </table-inner>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import utils from '@/assets/js/utils'
export default {
  components: { IcsDialogInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        channelCode: '',
        channelName: '',
        channelSocialNo: '',
        enabled: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      selectChannel: '',
      multipleSelection: undefined,
      searchNotCoreForm: {
        coreName: '',
        coreSocialNo: ''
      },
      loadingCore: {
        list: false
      },
      notChannelOfCore: {},
      dialog: {
        visible: false,
        title: ''
      },
      columnOption: [
        { label: '运营企业编号', prop: 'channelCode', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 100 },
        { label: '运营企业名称', prop: 'channelName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '统一社会信用代码', prop: 'channelSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'enabled', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'enableStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.company.channel.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    associated () {
      if (!this.selectChannel) {
        this.$message.error('请选择要进行关联的运营方')
        return false
      }
      this.dialog.title = '选择关联的核心企业'
      this.dialog.visible = true
      this.getNotChannelOfCoreList({})
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    getNotChannelOfCoreList (searchNotCoreForm) {
      this.loadingCore.list = true
      this.api.company.channel.getNotChannelOfCore(this.utils.filterTrimValue(searchNotCoreForm)).then(res => {
        this.notChannelOfCore = res.data.data || []
      }).finally(() => {
        // 解放loading
        this.loadingCore.list = false
      })
    },
    searchNotCore () {
      this.getNotChannelOfCoreList(this.searchNotCoreForm)
    },
    resetNotCore () {
      this.searchNotCoreForm.coreSocialNo = ''
      this.searchNotCoreForm.coreName = ''
      this.getNotChannelOfCoreList(this.searchNotCoreForm)
    },
    changeNotCorePageNum (val) {
      this.searchNotCoreForm.pageNum = val.pageNum
      this.getNotChannelOfCoreList(this.searchNotCoreForm)
    },
    confirmInvoice () {
      if (this.multipleSelection === undefined) {
        this.$message.error('请选择核心企业')
        return false
      }
      const data = {
        channelId: this.selectChannel,
        coreList: this.multipleSelection
      }
      this.api.company.channel.setCore(data).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('操作成功')
          this.getList()
          this.multipleSelection = undefined
          this.dialog.visible = false
          this.selectChannel = ''
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    },
    enableChannel (channelId, enabled) {
      let api = ''
      if (enabled === 0) {
        api = this.api.company.channel.disable
      } else {
        api = this.api.company.channel.enable
      }
      api(channelId).then(result => {
        this.$message.success(result.data.message || '操作成功')
        this.getList()
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
